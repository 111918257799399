/* Toggle Switch */

.toggle-switch {
	position: relative;
	display: inline-block;
	width: 48px;
	height: 25px;
	.toggle-slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $toggle-switch-bg;
		-webkit-transition: .4s;
		transition: .4s;
		border-radius: 34px;
		border: 1px solid $border-color;
		&:before {
			position: absolute;
			content: "";
			height: 16px;
			width: 16px;
			left: 4px;
			bottom: 4px;
			background-color: $white;
			-webkit-transition: .4s;
			transition: .4s;
			border-radius: 50%;
		}
	}
	input {
		visibility: hidden;
		&:checked {
			+.toggle-slider {
				background-color: theme-color(primary);
				&:before {
					-webkit-transform: translateX(23px);
					-ms-transform: translateX(23px);
					transform: translateX(23px);
				}
			}
		}
	}
}

@each $color,
$value in $theme-colors {
	.toggle-switch {
		&.toggle-switch-#{$color} {
			input {
				&:checked {
					+.toggle-slider {
						background-color: theme-color($color);
					}
				}
			}
		}
	}
}


/* Rounded sliders */