@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-ExtraBold.eot');
  src: url('../../fonts/Poppins/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Poppins/Poppins-ExtraBold.woff2') format('woff2'),
      url('../../fonts/Poppins/Poppins-ExtraBold.woff') format('woff'),
      url('../../fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-Medium.eot');
  src: url('../../fonts/Poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Poppins/Poppins-Medium.woff2') format('woff2'),
      url('../../fonts/Poppins/Poppins-Medium.woff') format('woff'),
      url('../../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-Black.eot');
  src: url('../../fonts/Poppins/Poppins-Black.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Poppins/Poppins-Black.woff2') format('woff2'),
      url('../../fonts/Poppins/Poppins-Black.woff') format('woff'),
      url('../../fonts/Poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-Thin.eot');
  src: url('../../fonts/Poppins/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Poppins/Poppins-Thin.woff2') format('woff2'),
      url('../../fonts/Poppins/Poppins-Thin.woff') format('woff'),
      url('../../fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-Regular.eot');
  src: url('../../fonts/Poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
      url('../../fonts/Poppins/Poppins-Regular.woff') format('woff'),
      url('../../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-ExtraLight.eot');
  src: url('../../fonts/Poppins/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Poppins/Poppins-ExtraLight.woff2') format('woff2'),
      url('../../fonts/Poppins/Poppins-ExtraLight.woff') format('woff'),
      url('../../fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-SemiBold.eot');
  src: url('../../fonts/Poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
      url('../../fonts/Poppins/Poppins-SemiBold.woff') format('woff'),
      url('../../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-Bold.eot');
  src: url('../../fonts/Poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Poppins/Poppins-Bold.woff2') format('woff2'),
      url('../../fonts/Poppins/Poppins-Bold.woff') format('woff'),
      url('../../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-Light.eot');
  src: url('../../fonts/Poppins/Poppins-Light.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Poppins/Poppins-Light.woff2') format('woff2'),
      url('../../fonts/Poppins/Poppins-Light.woff') format('woff'),
      url('../../fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

