////////// SIDEBAR ////////
$sidebar-width-lg: 236px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;
$sidebar-margin-left: 0.5rem;
$sidebar-margin-bottom: 2.062rem;

$sidebar-light-bg: #ffffff;
$sidebar-light-menu-color: #001737;
$sidebar-light-submenu-color: #001737;
$sidebar-light-menu-active-bg: #f7f8fc;
$sidebar-light-menu-active-color: #12253f;
$sidebar-light-menu-hover-bg: #f7f8fc;
$sidebar-light-menu-hover-color: #56595a;
$sidebar-light-submenu-hover-color: $black;
$sidebar-light-submenu-active-color: $black;
$sidebar-light-menu-icon-color: #6c7293;
$sidebar-light-menu-arrow-color: #686868;
$sidebar-light-profile-name-color: #404852;
$sidebar-light-profile-title-color: #8d9498;

$sidebar-dark-bg: #282f3a;
$sidebar-dark-menu-color: #d0cfcf;
$sidebar-dark-menu-active-bg: initial;
$sidebar-dark-menu-active-color: #c0bbbb;
$sidebar-dark-menu-hover-bg: #3b424c;
$sidebar-dark-menu-hover-color: #d0cfcf;
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: #9a94a7;
$sidebar-dark-menu-icon-color: $sidebar-dark-menu-color;
$sidebar-dark-profile-name-color: #ffffff;
$sidebar-dark-profile-title-color: #8d9498;

$sidebar-menu-font-size: .875rem;
$sidebar-menu-padding: 0.87rem .875rem 0.87rem .6rem;

$sidebar-submenu-padding: .25rem 0 0 1.1rem;
$sidebar-submenu-font-size: .875rem;
$sidebar-submenu-item-padding: .7rem 1rem;

$sidebar-icon-font-size:  1.215rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$sidebar-box-shadow: 0 5px 10px 0 rgba(230, 230, 243, 0.88);

$rtl-sidebar-submenu-padding: 0 4.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-bg;
$icon-only-menu-bg-dark: $sidebar-dark-bg;

///////// SIDEBAR ////////

///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// SETTINGS PANEL ////////

$settings-panel-width: 300px;

///////// NAVBAR ////////

$navbar-default-bg: #fff;
$navbar-height: 4.625rem;
$navbar-menu-color: #001737;
$navbar-font-size: 1rem;
$navbar-icon-font-size: .9375rem;
$navbar-breadcrumb-height: 4.062rem;
///////// NAVBAR ////////